$transition: .3s all ease-in-out;

.sidebar .sidebar-section {
  font-size: 1.4rem;
}

.sidebar-section .section-header {
  margin: 0 0 10px;
  background: url(../imgs/slash.jpg) right center;
  background-size: auto 22px;

  span {
    background-color: white;
    padding-right: 10px;
  }
}

.sidebar-subscribe {
  margin-top: 10px;
  position: relative;

  input {
    padding-left: 9px;
    display: inline-block;
    width: 66%;
    border-radius: 3px;
  }
}

a.sidebar-submit {
  display: block;
  background-color: #FFF300;
  border-radius: 3px;
  border: none;
  width: 100%;
  text-align: center;
  color: #333;
  padding: 6px;
  margin-top: 10px;
  transition: $transition;
}

a.sidebar-submit:visited {
  color: #333;
}

.sidebar-submit:hover {
  background-color: #DED300;
  color: #333;
  transition: $transition;
  -webkit-transition: $transition;
  -o-transition: $transition;
  -moz-transition: $transition;
}

.email-hint {
  position: absolute;
  line-height: 37px;
  left: 10px;
}

.icon-email {
  margin-right: 3px;
}

.sidebar-subscribe button {
  float: right;
  margin-top: 0;
  width: 30%;
  border: none;
  font-size: 1.4rem;
  padding: 5px;
}

.sidebar-tabs ul.nav li {
  display: inline-block;
}

.side-bar {
  position: fixed;
  bottom: 140px;
  right: 30px;
  padding: 0;
  z-index: 17;

  &-item {
    position: relative;
    list-style: none;
    transition: $transition;
    text-align: center;
    width: 45px;
    height: 45px;
    line-height: 45px;
    margin-bottom: 6px;
    background-color: rgba(39, 41, 46, 0.6);

    &>div {
      display: none;
      position: absolute;
      left: -165px;
      top: 0;
      width: 150px;
      margin-right: 20px;
      background-color: #FFFFFF;
      box-shadow: 2px 2px 10px 2px rgba(0, 0, 0, 0.06);
      font-size: 14px;

      a {
        font-size: 14px;
        color: #27292E;
      }

      // .triangle-right {
      //   position: absolute;
      //   right: -10px;
      //   top: 50%;
      //   transform: translateY(-50%);
      //   border-left: 5px solid #FCDB00;
      // }
    }

    &:hover div {
      display: block;
    }

    &>div.item_qr_code {
      width: 103px;
      height: 103px;
      left: -118px;
      top: -29px;
      border: 13px solid #FFFFFF;
      background-size: contain;
      background-repeat: no-repeat;
      box-shadow: 4px 4px 10px 3px rgba(0, 0, 0, 0.1);
    }

    &>div.report {
      background-image: url('../imgs/index2.0/new_report_qr_code.png');
    }

    &>div.finance {
      background-image: url('../imgs/index2.0/finance_qr_code.jpeg');
    }

    .icon-top {
      font-size: 20px;
      color: #FFFFFF;
    }

    &>p {
      display: none;
      position: absolute;
      top: 0px;
      left: 0px;
      width: 100%;
      height: 100%;
      background-color: rgba(39, 41, 46, 0.9);
      padding: 7px 10px;
      font-size: 11px;
      font-family: PingFangSC-Regular;
      font-weight: 400;
      color: rgba(255, 255, 255, 1);
      line-height: 14px;
      cursor: pointer;
      // z-index: 10;
    }
    &:hover p {
      display: block;
    }
  }

  &__report {
    position: relative;
    background-image: url('../imgs/icon/report_icon.png');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 50% 50%;
  }

  &__finance {
    position: relative;
    background-image: url('../imgs/icon/financing_icon.png');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 50% 50%;
  } 

  &__service {
    background-image: url('../imgs/icon/customer_service_icon.png');
    background-repeat: no-repeat;
    background-position: 50% 50%;
    background-size: 60% 60%;
  }
}

#topArrow {
  display: none; // opacity: 0;
}

.share_qrcode .qrcode {
  width: 30%;
  margin-top: 70px;
  margin-left: auto;
  margin-right: auto;
  top: 150px;
  box-shadow: 0px 1px 2px 0px rgba(68, 68, 68, 0.2);
  border-radius: 3px;
  padding: 10px 20px;
  font-size: 1.4rem;

  h3 {
    margin: 10px 0 0 0;
    font-size: 1.6rem;
  }
}

#qr-div {
  text-align: center;
  padding: 20px 0;

  canvas {
    width: 100%;
  }
}

.qrcode .fa-times {
  color: #838383;
  font-size: 2.6rem;

  &:hover {
    cursor: pointer;
    color: #626262;
    transition: $transition;
  }
}

.qr-wra {
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  height: 100%;
  position: fixed;
  top: 0;
  z-index: 16;
  display: none;
}

.sidebar {
  .nav-tabs {
    border-bottom: none;

    >li {
      &.active>a {

        &:hover,
        &:focus {
          border: none;
        }
      }

      >a {
        color: #333;
        border: none;

        &:hover {
          border: none;
          background-color: transparent;
        }
      }
    }
  }

  .nav li.active {
    display: inline-block;
    border-bottom: 2px solid #FFF300;
    background-color: #fff;
  }

  .nav-tabs>li {
    margin-bottom: 5px;
  }

  .nav>li {
    >a {
      padding: 0;
    }

    width: 25%;
    text-align: center;
  }
}

.section-content {
  color: #939393;
  font-size: 1.4rem;
}

.item {
  .pic1 {
    width: 60px;
    margin-right: -80px;
    float: left;
    overflow: hidden;
  }

  .item-pic {
    width: 30px;
    margin-right: 3px;
    float: left;
    overflow: hidden;
  }
}

img.avatar {
  border-radius: 50%;
}

.comment-src {
  width: 45%;
  line-height: 30px;
  overflow: hidden;
  text-overflow: ellipsis;
  float: right;
  white-space: nowrap;
  color: #9b9b9b;
}

.comment-content {
  a {
    color: #666;
  }

  color: #9b9b9b;
  background-color: #f8f8f8;
  border-radius: 3px;
  padding: 5px;
  margin-bottom: 5px;
}

.tab-content .item {
  overflow: hidden;
  padding: 10px 0;

  &:hover a {
    text-decoration: none;
  }
}

.comment-author {
  float: left;

  a {
    line-height: 30px;
  }
}

#hot-comment .item,
#new-comment .item {
  border-bottom: 1px solid #e8e8e8;
}

#hot-comment .item:last-child,
#new-comment .item:last-child {
  border-bottom: none;
}

.tab-content .item:last-child {
  margin: 0;
  padding-bottom: 0;
  border: none;
}

.item-pic1 {
  width: 24%;
  height: 78px;
  /*margin-right: -80px;*/
  float: left;
  overflow: hidden;

  img {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}

.item .item-content {
  margin-left: 28%;
  font-size: 1.4rem;
  text-align: justify;
  min-height: 60px;
  color: #333;

  &:hover {
    color: #4cc1fc;
  }
}

.item-time {
  border-top: 1px solid #ebebeb;
  margin-top: 5px;
  padding-top: 5px;
  color: #9b9b9b;
}

#side_fix a {
  color: #333;
}

@media screen and (max-width: 768px) {
  .qrcode {
    width: 50%;
  }

  .to-top li {
    display: none;

    &:first-child {
      display: block;
    }
  }
}

@media screen and (max-width: 500px) {
  .to-top {
    right: 14px;
    bottom: 90px;
  }

  .qrcode {
    width: 70%;
  }
}